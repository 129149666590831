import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["financialdata"];

  connect() {
    this.setValues(
      JSON.parse(this.financialdataTarget.dataset.info),
      String(this.financialdataTarget.value),
      "recent"
    );
  }

  changeRecent(event) {
    let selectedTime = event.target.value;

    this.setValues(
      JSON.parse(this.financialdataTarget.dataset.info),
      selectedTime,
      "recent"
    );
  }

  changeActFirst(event) {
    let selectedTime = event.target.value;

    this.setValues(
      JSON.parse(this.financialdataTarget.dataset.info),
      selectedTime,
      "act1"
    );
  }

  async setValues(financial_data, selected, prefix) {
    let {
      turnover,
      ebitda,
      equityRatio,
      ibdEbitda,
      rating,
    } = await this.getValuesForFields(financial_data, selected);

    let recentTurnover = document.getElementById(`${prefix}-turnover`);
    let recentEbidta = document.getElementById(`${prefix}-ebitda`);
    let recentEquityRatio = document.getElementById(`${prefix}-equity-ratio`);
    let recentIbdEbitda = document.getElementById(`${prefix}-ibd-ebitda`);
    let recentRating = document.getElementById(`${prefix}-rating`);

    recentTurnover.textContent = await this.setText(turnover);
    recentEbidta.innerText = await this.setText(ebitda);
    recentEquityRatio.innerText = (await this.setText(equityRatio)) + " %";
    recentIbdEbitda.innerText = await this.setText(ibdEbitda);
    recentRating.innerText = await this.setText(rating);
  }

  async getValuesForFields(financial_data, selected) {
    let turnover = await this.getRow(financial_data, selected, 640);
    let ebitda = await this.getRow(financial_data, selected, 1270);
    let equityRatio = await this.getRow(financial_data, selected, 1070);
    let ibdEbitda = await this.getRow(financial_data, selected, 1090);
    let rating = await this.getRow(financial_data, selected, 1590);

    return { turnover, ebitda, equityRatio, ibdEbitda, rating };
  }

  async getRow(fd, selected, dataId) {
    let found_row;

    fd.forEach((row) => {
      if (row.timeframe == selected && row.data_id == dataId) {
        found_row = row;
      }
    });

    return found_row;
  }

  async setText(obj) {
    if (obj && obj.value != "n/a" && obj.value != null) {
      return obj.value;
    } else {
      return "-";
    }
  }
}
