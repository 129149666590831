import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'form', 'list' ];

  page = 1;
  pageHeight = 0;
  loading = false;
  scrollFunction = this.handleScroll.bind(this);

  connect() {
    window.addEventListener('scroll', this.scrollFunction);
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollFunction);
  }

  handleScroll() {
    if (this.isScrolledToBottom()) {
      this.loadMoreFundingCases();
    }
  }

  isScrolledToBottom() {
    // Offset footer height
    return window.innerHeight + window.scrollY >= (document.body.offsetHeight - 400);
  }

  showLoadingIndicator() {
    this.loading = true;
    document.getElementById('loading-indicator').style.visibility = 'visible';
  }

  hideLoadingIndicator() {
    this.loading = false;
    document.getElementById('loading-indicator').style.visibility = 'hidden';
  }

  loadMoreFundingCases() {
    if (this.loading) return;

    // Allow loading only if page height has increased since last time
    if (document.body.offsetHeight <= this.pageHeight) return;
    this.pageHeight = document.body.offsetHeight;

    this.showLoadingIndicator();

    const url = new URL(window.location.href);

    // Include name from name input to the URL
    url.searchParams.set("name", this.formTarget.querySelector('[name="name"]').value);

    // Include statuses from checkboxes which are selected to the URL
    this.formTarget.querySelectorAll('[name="status[]"]:checked').forEach(checkbox => {
      url.searchParams.append("status[]", checkbox.value);
    });

    // Include updated page number to the URL
    this.page += 1;
    url.searchParams.set("page", this.page);

    fetch(url)
      .then(response => response.text())
      .then(html => {
        // Create a temporary div to hold the new items
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;

        // Fade in new elements by adding Home__FadeIn class
        tempDiv.querySelectorAll('.Home__Case').forEach(fundingCase => {
          fundingCase.classList.add('Home__FadeIn');
        });

        // Append the new items to the list
        this.listTarget.insertAdjacentHTML("beforeend", tempDiv.querySelector('[data-funding-search-target="list"]').innerHTML);

        this.hideLoadingIndicator();
      })
      .catch(error => {
        console.error(error);
        this.hideLoadingIndicator();
      });
  }

  search() {
    this.page = 1;
    this.pageHeight = 0;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 250)
  }
}