import { Controller } from "stimulus"
import Chart from "chart.js/auto"
import annotationPlugin from "chartjs-plugin-annotation"
import TomSelect from "tom-select"

Chart.register(annotationPlugin)

const barChartConfig = function(data, lineData, label1, label2) {
  const profitColors = data.map((value) => {
    if (value.status === "EST") {
      return value.val < 0 ? "#D89AA9" : "#B4B8CF"
    } else {
      return value.val < 0 ? "#AC0029" : "#2d4391"
    }
  })

  const actLine = lineData.filter((item) => item.status === "ACT")
  const estLine = lineData.filter((item) => item.status === "EST")

  const lastAct = actLine[actLine.length - 1] || 0
  const lastActToBePushed = { x: lastAct.x, val: lastAct.val, status: "EST" }

  const lastEst = estLine[0]
  let lastEstToBePushed = {}
  if (lastEst) lastEstToBePushed = { x: lastEst.x, val: lastEst.val, status: "EST" }

  const connectingLine = [lastActToBePushed, lastEstToBePushed]

  return {
    type: 'bar',
    data: {
      datasets: [
        {
          label: label1,
          data: data,
          parsing: {
            yAxisKey: "val",
          },
          order: 2
        },
        {
          label: label2,
          type: 'line',
          data: actLine,
          parsing: {
            yAxisKey: "val"
          },
          order: 1,
          backgroundColor: "#1796EB",
          borderColor: "#1796EB",
          borderCapStyle: "round",
          borderWidth: 5,
          tension: 0.2,
          pointStyle: "line",
          pointRadius: 0,
        },
        {
          label: label2,
          type: 'line',
          data: estLine,
          parsing: {
            yAxisKey: "val"
          },
          order: 0,
          backgroundColor: "#1796EB",
          borderColor: "#1796EB",
          borderCapStyle: "round",
          borderWidth: 5,
          tension: 0.2,
          pointStyle: "line",
          pointRadius: 0,
          borderDash: [10, 10]
        },
        {
          type: 'line',
          data: connectingLine,
          parsing: {
            yAxisKey: "val"
          },
          showInLegend: false,
          order: 0,
          backgroundColor: "#1796EB",
          borderColor: "#1796EB",
          borderCapStyle: "round",
          borderWidth: 5,
          tension: 0.2,
          pointStyle: "line",
          pointRadius: 0,
          borderDash: [10, 10]
        }
      ]
    },
    options: {
      animation: true,
      maintainAspectRatio: false,
      elements: {
        bar: {
          backgroundColor: profitColors,
        }
      },
      interaction: {
        intersect: false,
        momde: 'index'
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
          },
          title: {
            display: false,
            text: "tEur"
          },
          ticks: {
            color: "#2d4391",
            min: 0,
            max: 100,
          }
        },
        y2: {
          type: 'category',
          labels: ['tEur'],
          offset: true,
        },
        x: {
          ticks: {
            color: "#2d4391",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false
        },
        annotation: {
          annotations: {
            line: {
              type: "line",
              yMin: 0,
              yMax: 0
            }
          }
        }
      }
    }
  };
};

const stackedChartConfig = function(sales_data, payable_data, label1, label2) {
  const salesColors = sales_data.map((value) => {
    if (value.status === "EST") {
      return value.val < 0 ? "#D89AA8" : "#B4B8CF"
    } else {
      return value.val < 0 ? "#AC0029" : "#2d4391"
    }
  })

  const payableColors = payable_data.map((value) => {
    if (value.status === "EST") {
      return value.val < 0 ? "#D89AA8" : "#B6C9D9"
    } else {
      return value.val < 0 ? "#AC0029" : "#537EA0"
    }
  })

  return {
    type: 'bar',
    data: {
      datasets: [
        {
          label: label1,
          data: sales_data,
          parsing: {
            yAxisKey: "val"
          },
          backgroundColor: salesColors
        },
        {
          label: label2,
          data: payable_data,
          parsing: {
            yAxisKey: "val"
          },
          backgroundColor: payableColors
        }
      ]
    },
    options: {
      animation: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
          },
          ticks: {
            color: "#2d4391",
          }
        },
        y2: {
          type: 'category',
          labels: ['tEur'],
          offset: true,
        },
        x: {
          ticks: {
            color: "#2d4391",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            line: {
              type: "line",
              yMin: 0,
              yMax: 0
            }
          }
        }
      }
    }
  };
};

export default class extends Controller {
  initialize() {
    this.setupMonthlyChart();
    this.setupCumulativeChart();
    this.setupCashAndCapitalChart();
    this.setupAccountsAndSalesChart();
    this.reloadTinyMce();

    // Initialize TomSelect, ensure email box is available
    if (document.getElementById("EmailDocuments") !== null) {
      new TomSelect("#receivers", {
        maxOptions: 256,
        plugins: {
          remove_button: {},
        },
        render: { no_results: null, option_create: null },
        persist: false,
        createOnBlur: true,
        create: true,
      });
  
      new TomSelect("#email_document_ids", {
        maxOptions: 256,
        plugins: {
          remove_button: {},
        },
      });
    }
  }

  setupMonthlyChart() {
    const monthlyCtx = document.getElementById("monthly-chart");
    if (monthlyCtx === null) return;

    const monthlyEbitda = JSON.parse(monthlyCtx.dataset.monthlyebitdaoperatingmarging);
    const monthlyTurnover = JSON.parse(monthlyCtx.dataset.monthlyturnover)
    if (monthlyEbitda == null && monthlyTurnover == null) return;

    const label1 = monthlyCtx.dataset.firstmonthlylabel
    const label2 = monthlyCtx.dataset.secondmonthlylabel

    new Chart(monthlyCtx, barChartConfig(monthlyTurnover.data, monthlyEbitda.data, label1, label2))
  }

  setupCumulativeChart() {
    const cumulativeCtx = document.getElementById("cumulative-chart");
    if (cumulativeCtx === null) return;

    const cumulativeProfit = JSON.parse(cumulativeCtx.dataset.cumulativeprofit);
    const cumulativeTurnover = JSON.parse(cumulativeCtx.dataset.cumulativeturnover);
    if (cumulativeProfit == null && cumulativeTurnover == null) return;

    const label1 = cumulativeCtx.dataset.firstcumullabel
    const label2 = cumulativeCtx.dataset.secondcumullabel

    new Chart(cumulativeCtx, barChartConfig(cumulativeTurnover.data, cumulativeProfit.data, label1, label2))
  }

  setupCashAndCapitalChart() {
    const cashAndCapitalCtx = document.getElementById("chashandcapital-chart");
    if (cashAndCapitalCtx === null) return;

    const ownCapital = JSON.parse(cashAndCapitalCtx.dataset.owncapital);
    const cash = JSON.parse(cashAndCapitalCtx.dataset.cash);
    if (ownCapital == null && cash == null) return;

    const label1 = cashAndCapitalCtx.dataset.firstcashcaplabel
    const label2 = cashAndCapitalCtx.dataset.secondcashcaplabel

    new Chart(cashAndCapitalCtx, barChartConfig(cash.data, ownCapital.data, label1, label2))
  }

  setupAccountsAndSalesChart() {
    const accountsAndSalesCtx = document.getElementById("accountsandsales-chart");
    if (accountsAndSalesCtx === null) return;

    const accountsAndSales = JSON.parse(accountsAndSalesCtx.dataset.accountsandsales);
    if (accountsAndSales == null) return;

    const label1 = accountsAndSalesCtx.dataset.firstaccsaleslabel
    const label2 = accountsAndSalesCtx.dataset.secondaccsaleslabel

    new Chart(accountsAndSalesCtx, stackedChartConfig(accountsAndSales.sales_data, accountsAndSales.payable_data, label1, label2))
  }

  reloadTinyMce() {
    // Tinymce does not play nice with turbo
    // Remove & initialize it again to DOM to make editor usable
    setTimeout(() => {
      tinymce.remove();
      tinymce.init({
        selector: '.tinymce',
        menubar: false,
        elementpath: false,
        toolbar: 'styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link unlink',
        plugins: [
          'link',
          'lists'
        ],
        paste_as_text: true,
        branding: false
      });
    }, 500);
  }
}
