// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start()

require("channels");

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// load jqueru-ui datepicker & associated css
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/themes/base/all.css');
global.$.datepicker.setDefaults({
  changeMonth: true,
  changeYear: true,
  dateFormat: 'dd.mm.yy',
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "stylesheets/application";
import "bootstrap";
import "controllers";
import "tom-select/dist/css/tom-select.css";
