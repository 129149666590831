import { Controller } from "stimulus";
import Chart from 'chart.js/auto';

const NUMBER_TO_RATING = {
  10: 'AAA',
  9: 'AA',
  8: 'A',
  7: 'BBB',
  6: 'BB',
  5: 'B',
  4: 'CCC',
  3: 'CC',
  2: 'C',
  1: 'F',
  0: '', // n/a, not shown
};

const lineChartConfig = function(labels, data) {
  return {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        data: data
      }]
    },
    options: {
      animation: false,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.3,
          borderWidth: 4,
          borderColor: '#1796EB',
          borderJoinStyle: 'round',
          borderCapStyle: 'round'
        },
        point: {
          radius: 0, // Hides points
          hitRadius: 10
        }
      },
      scales: {
        y: {
          beginAtZero: false,
          max: Math.round(Math.max(...data) + 1), // Some extra scale padding
          min: Math.round(Math.min(...data) - 1),
          grid: {
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            minRotation: 45,
            maxRotation: 45
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function(value) { return value.formattedValue + ' %' }
          }
        }
      }
    }
  };
};

const filledLineChartConfig = function(gradient, gradientSecond, labels, data1, data2, data3) {
  return {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          fill: false,
          borderWidth: 2,
          borderColor: '#1796EB',
          borderDash: [5, 5],
          data: data3,
        },
        {
          fill: true,
          backgroundColor: gradient,
          data: data1
        },
        {
          fill: true,
          backgroundColor: gradientSecond,
          data: data2
        }
      ]
    },
    options: {
      animation: false,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1,
          borderWidth: 1,
          borderColor: '#838AB6',
          borderJoinStyle: 'round',
          borderCapStyle: 'round'
        },
        point: {
          radius: 0, // Hides points
          hitRadius: 10
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 10,
          grid: {
            drawBorder: false,
          },
          ticks: {
            min: 0,
            max: 10,
            stepSize: 1,
            callback: function(value) { return NUMBER_TO_RATING[value] }
          }
        },
        x: {
          ticks: {
            minRotation: 45,
            maxRotation: 45
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function(value) { return NUMBER_TO_RATING[value.formattedValue] }
          }
        }
      }
    }
  };
};

const barChartConfig = function(labels, data) {
  return {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        data: data
      }]
    },
    options: {
      animation: false,
      maintainAspectRatio: false,
      elements: {
        bar: {
          backgroundColor: '#1796EB',
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
          },
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) { return value + ' %' }
          }
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function(value) { return value.formattedValue + ' %' }
          }
        }
      }
    }
  };
};

export default class extends Controller {
  initialize() {
    // Portfolio show page
    this.setupPerformanceChart();
    this.setupCreditRatingChart();
    this.setupLoanDurationChart();

    // Portfolio company show page
    this.setupSingleCompanyCreditRatingChart();
  }

  setupPerformanceChart() {
    const performanceCtx = document.getElementById('performance-chart');
    if (performanceCtx === null || performanceCtx.dataset.performance === '') return;

    const performanceData = JSON.parse(performanceCtx.dataset.performance);
    if (performanceData === null) return;

    new Chart(performanceCtx, lineChartConfig(performanceData.labels, performanceData.data));
  }

  setupCreditRatingChart() {
    const creditRatingCtx = document.getElementById('credit-rating-chart');
    if (creditRatingCtx === null || creditRatingCtx.dataset.creditRating === '') return;

    const creditRatingData = JSON.parse(creditRatingCtx.dataset.creditRating);
    if (creditRatingData === null) return;

    new Chart(creditRatingCtx, barChartConfig(creditRatingData.labels, creditRatingData.data));
  }

  setupLoanDurationChart() {
    const loanDurationCtx = document.getElementById('loan-duration-chart');
    if (loanDurationCtx === null || loanDurationCtx.dataset.loanDuration === '') return;

    const loanDurationData = JSON.parse(loanDurationCtx.dataset.loanDuration);
    if (loanDurationData === null) return;

    new Chart(loanDurationCtx, barChartConfig(loanDurationData.labels, loanDurationData.data));
  }

  setupSingleCompanyCreditRatingChart() {
    const creditRatingCtx = document.getElementById('single-company-credit-rating-chart');
    if (creditRatingCtx === null || creditRatingCtx.dataset.creditRating === '') return;

    // Setup gradient
    const ctx = creditRatingCtx.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(131, 138, 182, 0.5)');
    gradient.addColorStop(1, 'rgba(131, 138, 182, 0)');
    const gradientSecond = ctx.createLinearGradient(0, 0, 0, 400);
    gradientSecond.addColorStop(0, 'rgba(50, 50, 50, 0.1)');
    gradientSecond.addColorStop(1, 'rgba(50, 50, 50, 0)');

    const creditRatingData = JSON.parse(creditRatingCtx.dataset.creditRating);
    if (creditRatingData === null) return;

    new Chart(
      creditRatingCtx,
      filledLineChartConfig(
        gradient,
        gradientSecond,
        creditRatingData.labels,
        creditRatingData.data.act_ratings,
        creditRatingData.data.est_ratings,
        creditRatingData.data.rating_limits
      )
    );
  }
}
