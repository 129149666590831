import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    this.showTab();
  }

  change(e) {
    this.index = this.tabTargets.indexOf(e.currentTarget);
    this.showTab();
  }

  showTab() {
    const new_user_elem = document.getElementById("new_user_panel");
    const new_org_elem = document.getElementById("new_org_panel");

    if (this.index == 0) {
      new_user_elem.style.display = "block";
      new_org_elem.style.display = "none";
    } else {
      new_user_elem.style.display = "none";
      new_org_elem.style.display = "block";
    }

    this.removeAndAddClasses();
  }

  removeAndAddClasses() {
    this.tabTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.add("Invitations__Tabs__TabbedNavItem--active");
      } else if (this.index == -1) {
        return;
      } else {
        el.classList.remove("Invitations__Tabs__TabbedNavItem--active");
      }
    });
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
