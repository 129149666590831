import {Controller} from "stimulus"

export default class extends Controller {
  toggle() {
    const show = document.querySelectorAll('[id=covenant_fee_show]')
    const edit = document.querySelectorAll('[id=covenant_fee_edit]')
    const submit = document.getElementById("fee_submit")

    show.forEach(elem => {
      const style = elem.style.display === "none" ? "table-cell" : "none"
      elem.style.display = style
    })

    edit.forEach(elem => {
      const estyle = elem.style.display === "none" ? "table-cell" : "none"
      elem.style.display = estyle
    })

    const but_style = submit.style.display === "none" ? "block" : "none"
    submit.style.display = but_style
  }
}
