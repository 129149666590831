import { Controller } from "@hotwired/stimulus";
import {
  create,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
  get,
} from "@github/webauthn-json/browser-ponyfill";

export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", (e) => {
      if (e.submitter.name === "authenticate") {
        this.authenticate(e);
      } else {
        this.register(e);
      }
    });
  }

  async register(e) {
    e.preventDefault();

    const options = parseCreationOptionsFromJSON({
      publicKey: JSON.parse(this.element.dataset.webauthnOptions),
    });
    const credential = await create(options).catch(() => {
      // If the user cancels the registration, reload the page to reset the form
      window.location.reload();
    });

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "credential[data]";
    const binString = Array.from(
      Uint8Array.from(JSON.stringify(credential), (c) => c.charCodeAt(0)),
    ).map((b) => String.fromCharCode(b)).join("");
    hiddenField.value = btoa(binString);
    this.element.appendChild(hiddenField);
    this.element.submit();
  }

  async authenticate(e) {
    e.preventDefault();

    const options = parseRequestOptionsFromJSON({
      publicKey: JSON.parse(this.element.dataset.webauthnOptions),
    });
    const credential = await get(options).catch(() => {
      // If the user cancels the authentication, reload the page to reset the form
      window.location.reload();
    });

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "credential[data]";
    const binString = Array.from(
      Uint8Array.from(JSON.stringify(credential), (c) => c.charCodeAt(0)),
    ).map((b) => String.fromCharCode(b)).join("");
    hiddenField.value = btoa(binString);
    this.element.appendChild(hiddenField);
    this.element.submit();
  }
}
