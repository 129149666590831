import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["droparea", "fileinput", "form", "filename"];

  connect() {
    // disable pointer events for droparea child nodes to avoid unnecessary dragleave events
    for (let node of this.dropareaTarget.childNodes) {
        if (node.style !== undefined) {
            node.style.pointerEvents = 'none';
            //node.style.cssText = 'pointer-events: none';
        }
    }

    // hide file input and submit
    this.fileinputTarget.style.display = 'none';

    // add event listeners
    this.dropareaTarget.addEventListener('click', this.clickHandler.bind(this));
    this.dropareaTarget.addEventListener('dragenter', this.dragenterHandler.bind(this));
    this.dropareaTarget.addEventListener('dragover', this.dragoverHandler.bind(this));
    this.dropareaTarget.addEventListener('dragleave', this.dragleaveHandler.bind(this));
    this.dropareaTarget.addEventListener('drop', this.dropHandler.bind(this));
    this.fileinputTarget.addEventListener('change', this.changeHandler.bind(this));
    
  }

  disconnect() {
    this.dropareaTarget.removeEventListener('click', this.clickHandler.bind(this));
    this.dropareaTarget.removeEventListener('dragleave', this.dragleaveHandler.bind(this));
    this.dropareaTarget.removeEventListener('dragenter', this.dragenterHandler.bind(this));
    this.dropareaTarget.removeEventListener('dragover', this.dragoverHandler.bind(this));
    this.dropareaTarget.removeEventListener('dragleave', this.dragleaveHandler.bind(this));
    this.dropareaTarget.removeEventListener('drop', this.dropHandler.bind(this));
    this.fileinputTarget.removeEventListener('change', this.changeHandler.bind(this));
  }

  clickHandler(event) {
    event.preventDefault();
    this.fileinputTarget.click();
  }

  dragenterHandler(event) {
    event.preventDefault();
    this.dropareaTarget.classList.add('drag-is-over');
  }

  dragoverHandler(event) {
    event.preventDefault();
    this.dropareaTarget.classList.add('drag-is-over');
  }

  dragleaveHandler(event) {
    event.preventDefault();
    this.dropareaTarget.classList.remove('drag-is-over');
  }

  dropHandler(event) {
    event.preventDefault();
    this.dropareaTarget.classList.remove('drag-is-over');
    if (event.dataTransfer.files.length > 0) {
      this.fileinputTarget.files = event.dataTransfer.files;
    }
  }

  changeHandler(event) {
    this.filenameTarget.innerHTML = this.fileinputTarget.value.split(/(\\|\/)/g).pop()
    event.preventDefault();
  }
}
